import React from "react";
import classnames from "classnames";
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";
import Styles from "./CancelBidConfirmationModal.module.scss";
import AppImg from "../../../../components/utils/AppImg";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
export interface CancelBidProps {
    isVisible: boolean;
    handleCancelBidToggle: () => void;
    onClickConfirmCancel: () => void;
    onClickSubmitBid: () => void;
}

const CancelBidConfirmation = ({
    isVisible,
    handleCancelBidToggle,
    onClickConfirmCancel,
    onClickSubmitBid
}: CancelBidProps) => {
    const mobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <>
            <ModalOrBottomSheetWrapper
                isOpen={isVisible}
                onToggle={handleCancelBidToggle}
                showHeader={false}
                modalSize="fit"
            >
                <>
                    <div className={Styles.iconContainer}>
                        <AppImg
                            src={"CancelBidDesktop.png"}
                            className={classnames(mobileScreen ? Styles.cancelLimit_mob : Styles.cancelLimit_web)}
                            alt="Cancel Bid?"
                            width={mobileScreen ? "218" : "416"}
                            height={mobileScreen ? "164" : "312"}
                        />
                    </div>
                    <div
                        className={classnames(
                            Styles.MainContainer,
                            mobileScreen ? Styles.MainContainer__mob : Styles.MainContainer__web
                        )}
                    >
                        <div
                            className={classnames(
                                Styles.CancelHeading,
                                mobileScreen ? Styles.CancelHeading__mob : Styles.CancelHeading__web
                            )}
                        >
                            Are you sure you want to cancel your bid?
                        </div>

                        <div className={classnames(mobileScreen ? Styles.ButtonStyle__mob : Styles.ButtonStyle__web)}>
                            <AppBtn
                                width={"100%"}
                                isMobile={mobileScreen}
                                type="secondary"
                                btnType="button"
                                isInHoverState={true}
                                onClick={() => {
                                    onClickSubmitBid();
                                }}
                            >
                                <span style={{ verticalAlign: "middle" }}>Submit Bid</span>
                            </AppBtn>
                        </div>
                        <div
                            className={classnames(
                                Styles.confirmationHeading,
                                mobileScreen ? Styles.confirmationHeading__mob : Styles.confirmationHeading__web
                            )}
                            onClick={onClickConfirmCancel}
                        >
                            <p>Yes, Cancel My Bid</p>
                        </div>
                    </div>
                </>
            </ModalOrBottomSheetWrapper>
        </>
    );
};

export default CancelBidConfirmation;
