import * as Yup from "yup";

import { FormStep } from "@vahak/core/dist/custom-types/misc";
import { LorryMarketDetails } from "@vahak/core/dist/custom-types/lorry";
import { loadBidModel, lorryBidModel } from "../bidFormModels";
import { getCommonValidationSchema } from "../getCommonValidationSchema";
import { getStringWordByIndex } from "@vahak/core/dist/methods/getStringWordByIndex";
import { LorryDetailsDataType } from "../../../../../services/lorry/useGetLorryDetailsService";
import { LorryMarketDetailsV2 } from "../../../../../services/lorry/useGetLorryMarketV2/type";

const { fleet } = loadBidModel;

export function getLoadValidationSchema(activeStep: FormStep) {
    const _schema = {
        ...getCommonValidationSchema(),
        [fleet.id]: Yup.object().shape({
            [fleet.fields.lorryNum]: Yup.string().required("Required!"),
            [fleet.fields.lorryCapacity]: Yup.string().required("Required!")
            // [fleet.fields.]: Yup.string().required("Required!")
        })
    };
    return _schema[activeStep?.id];
}

interface LorryBidValidationSchemaParams {
    lorryDetails?: LorryMarketDetailsV2 | LorryMarketDetails | LorryDetailsDataType;
}

const { load } = lorryBidModel;

export function getLorryValidationSchema(activeStep: FormStep, { lorryDetails }: LorryBidValidationSchemaParams) {
    const { lorry_type_text } = (lorryDetails as LorryDetailsDataType) || {};
    const { type } = (lorryDetails as LorryMarketDetails) || {};

    const _schema = {
        ...getCommonValidationSchema(),
        [load.id]: Yup.object().shape({
            [load.fields.material]: Yup.string().required("Required!").max(50),
            [load.fields.qty]: Yup.number()
                .positive()
                .required("Required!")
                .max(
                    100,
                    `Quantity must be less than ${
                        (getStringWordByIndex(String(type), 1) || getStringWordByIndex(lorry_type_text, 1)) === "Tanker"
                            ? "100 Kilo Litres"
                            : "100 Tonnes"
                    }`
                ),
            [load.fields.src]: Yup.string().required("Required!"),
            [load.fields.dest]: Yup.string().required("Required!")
        })
    };
    return _schema[activeStep?.id];
}

export function getLorryBidValidationSchema() {
    return Yup.object().shape({
        [load.fields.loadId]: Yup.number().required("Select a load!")
    });
}
