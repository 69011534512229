import { LoadTicketType } from "./constants";

export const LOAD_VERIFIED = "Load Verified";
export const LOAD_VERIFIED_TAP_N_GO = "Load verified - TAP ‘N’ GO";

export interface IDisposition {
    dispositionName: string;
    subDispositions: string[];
}

export const LOAD_DISPOSITION = {
    [LoadTicketType.VERIFICATION]: [
        {
            dispositionName: "Valid Load",
            subDispositions: [
                LOAD_VERIFIED,
                // LOAD_VERIFIED_TAP_N_GO,
                "Deal Completed by TSM",
                "Organic Payment",
                "Conversion done"
            ]
        },
        {
            dispositionName: "Invalid Load",
            subDispositions: ["Mistook App", "Mistook feature", "Test load", "Load Cancelled"]
        },
        {
            dispositionName: "Deal Completed Outside",
            subDispositions: ["Payment already done outside", "Lorry owner wants payment in Cash", "Trust issue"]
        },
        {
            dispositionName: "Others",
            subDispositions: [
                "Concern Person not available",
                "Customer Disconnected",
                "Duplicate",
                "Wrong Number",
                "Language Barrier",
                "Blank Call",
                "Call Back request"
            ]
        },
        {
            dispositionName: "Suspected Fraud/Load",
            subDispositions: ["Army Fraud", "Airport Fraud", "Committed Financial Fraud"]
        },
        {
            dispositionName: "Load type missmatch",
            subDispositions: ["Part load", "Bulk load form filled"]
        },
        {
            dispositionName: "Not Connected",
            subDispositions: ["DNP", "DND"]
        }
    ],
    [LoadTicketType.POST_PLACEMENT]: [],
    // [LoadTicketType.POST_PLACEMENT]: [
    //     {
    //         dispositionName: "Valid Load",
    //         subDispositions: [
    //             LOAD_VERIFIED,
    //             "Deal Completed by TSM",
    //             "Organic Payment",
    //             "Conversion done",
    //             "Reposted and Verified",
    //             "Ongoing Conversation",
    //             "TSO Load"
    //         ]
    //     },
    //     {
    //         dispositionName: "Invalid Load",
    //         subDispositions: ["Mistook App", "Mistook feature", "Test load", "Load Cancelled"]
    //     },
    //     {
    //         dispositionName: "Deal Completed Outside",
    //         subDispositions: ["Payment already done outside", "Lorry owner wants payment in Cash", "Trust issue"]
    //     },
    //     {
    //         dispositionName: "Others",
    //         subDispositions: [
    //             "Concern Person not available",
    //             "Customer Disconnected",
    //             "Wrong Number",
    //             "Language Barrier",
    //             "Blank Call",
    //             "Call Back request",
    //             "Duplicate",
    //             "Unable to repost KYC issue"
    //         ]
    //     },
    //     {
    //         dispositionName: "Suspected Fraud/Load",
    //         subDispositions: ["Army Fraud", "Airport Fraud", "Committed Financial Fraud", "OTP Taken by someone"]
    //     },
    //     {
    //         dispositionName: "Load type missmatch",
    //         subDispositions: ["Part load", "Bulk load form filled"]
    //     },
    //     {
    //         dispositionName: "Lorry Side",
    //         subDispositions: [
    //             "Truck type mismatch",
    //             "Bymistakenly Accepted",
    //             "Material issue",
    //             "Price issue",
    //             "Pickup/Destination not searviceable",
    //             "Vehicle is Ready",
    //             "Mistakenly Bid Placed",
    //             "Vehicle Placed outside Vahak",
    //             "Mistook App",
    //             "Vehicle Ready Load owner not respondinng"
    //         ]
    //     },
    //     {
    //         dispositionName: "Not Connected",
    //         subDispositions: ["DNP", "DND"]
    //     }
    // ],
    [LoadTicketType.FULFILLMENT]: [
        {
            dispositionName: "Bid Accepted",
            subDispositions: []
        },
        {
            dispositionName: "Bid Not Accepted",
            subDispositions: ["Price Mismatch", "Load Ghosted After Bid Sent", "Load cancelled After Bid Sent"]
        },
        {
            dispositionName: "Bid Not Sent",
            subDispositions: ["Vehicle not available", "Truck Type mismatch", "Pickup/Destination not serviceable"]
        },
        {
            dispositionName: "Vehicle Placed Outside",
            subDispositions: [
                "Payment done before we contact",
                "Load Owner have trust issue",
                "LR wants payment in cash"
            ]
        },
        {
            dispositionName: "Deal Completed By TSO",
            subDispositions: []
        },
        {
            dispositionName: "Vehicle Placed Inside",
            subDispositions: ["LR wants payment in cash"]
        },
        {
            dispositionName: "Not Connected",
            subDispositions: ["RNR"]
        }
    ]
};
