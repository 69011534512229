import classNames from "classnames";
import React, { ReactNode, useContext, useState } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import { FormStep } from "@vahak/core/dist/custom-types/misc";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import CancelBidConfirmation from "../../cancel-bid/CancelBidConfirmationModal";

import Styles from "./FormContainer.module.scss";

// icons
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { MARKET_TYPE } from "@vahak/core/dist/constants/market";

import { NextRouter, useRouter } from "next/router";
import { LOAD_BID, LORRY_BID } from "../../../../../constants/modal-strings";
import { BiddingFormContext, BID_ACTIONS } from "../../../../../BiddingFormContextProvider";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";

function getLoadLorryParams(router: NextRouter, loadLorryId?: number | string, type?: MarketType) {
    return loadLorryId ? (type === "load" ? `${LOAD_BID}=${loadLorryId}` : `${LORRY_BID}=${loadLorryId}`) : undefined;
}

export interface FormContainerRef {
    open?: (type: MarketType, loadLorryId?: number) => void;
    close?: () => void;
}

export interface FormContainerProps {
    type: MarketType;
    activeStep: FormStep;
    formNode: ReactNode;
    footerNode?: ReactNode;
    trackingData?: Record<string, any>;
    fullScreenSheet?: boolean;
}
const FormContainer = ({
    type,
    activeStep,
    formNode,
    footerNode,
    trackingData,
    fullScreenSheet
}: FormContainerProps) => {
    let loadLorryParam: string | undefined;
    const { setShouldPreventLayoutSwitch, isPhoneVerified, companyId } = useContext(AppContext);
    const { bidFormConfig, dispatchBid } = useContext(BiddingFormContext);

    const resetBidForm = () => {
        dispatchBid({
            type: BID_ACTIONS.LOAD,
            payload: { id: 0, open: false }
        });
        const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];
        if (loadLorryId) {
            delete router.query["lorrybid"];
            delete router.query["loadbid"];
            delete router.query["oppcmpid"];
            delete router.query["utm_medium"];
            delete router.query["utm_source"];
            delete router.query["_branch_match_id"];
            delete router.query["_branch_referrer"];
            delete router.query["r"];
            delete router.query["u_i"];
            delete router.query["utm_campaign"];
            router.replace(router, { query: "" }, { shallow: true, scroll: false });
        }
    };

    const router = useRouter();
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const mktQuery = router.query["params"]?.[0];
    const isLoadMkt = mktQuery === MARKET_TYPE.LOAD;

    const [isConfirmCancelModalVisible, setIsConfirmCancelModalVisible] = useState(false);

    const trackEventWithName = (name: string) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                ...trackingData,
                source_screen: isLoadMkt ? "load_market" : "lorry_market",
                bs_type: type === "load" ? "load_bidding" : "lorry_bidding"
            }
        });
    };

    const closeConfirmCancel = async () => {
        setIsConfirmCancelModalVisible(false);
        setShouldPreventLayoutSwitch?.(false);

        if (router.route === "/" && Boolean(companyId)) {
            router.push(ROUTES.dashboard.getUrl());
        }
    };

    const _openBidModal = (type: MarketType, loadLorryId?: number) => {
        !isPhoneVerified && setShouldPreventLayoutSwitch?.(true);
        if (true) {
            loadLorryParam = getLoadLorryParams(router, loadLorryId, type);
            if (type === "load") {
                dispatchBid({ type: BID_ACTIONS.LOAD, payload: { open: true } });
            } else {
                dispatchBid({ type: BID_ACTIONS.LORRY, payload: { open: true } });
            }
        }
    };

    const _closeBidModal = () => {
        if (type === "load") {
            dispatchBid({ type: BID_ACTIONS.LOAD, payload: { open: false } });
        } else {
            dispatchBid({ type: BID_ACTIONS.LORRY, payload: { open: false } });
        }
        setShouldPreventLayoutSwitch?.(false);
    };

    const handleToggle = () => {
        if (bidFormConfig.load.open || bidFormConfig.lorry.open) {
            setIsConfirmCancelModalVisible(true);
            trackEventWithName(GA4EventNames.MARKET_PLACE["bs_close_clicked"]);
        } else {
            _openBidModal(type);
        }
    };

    const onClickConfirmCancel = () => {
        closeConfirmCancel();
        _closeBidModal();
        resetBidForm();
        trackEventWithName(GA4EventNames.MARKET_PLACE["canceled_bidding"]);
    };

    const handleCancelBidToggle = () => {
        if (isConfirmCancelModalVisible) {
            onClickConfirmCancel();
        } else {
            setIsConfirmCancelModalVisible(true);
        }
    };

    return (
        <>
            {bidFormConfig.load.open || bidFormConfig.lorry.open ? (
                <ModalOrBottomSheetWrapper
                    isOpen={bidFormConfig.load.open || bidFormConfig.lorry.open}
                    onToggle={handleToggle}
                    disableSheetSwipe
                    titleText={activeStep?.label}
                    renderFooter={footerNode}
                    fullScreenSheet={fullScreenSheet}
                    sheetBodyMinHeight={"300px"}
                >
                    <div className={Styles.formFieldsSection}>
                        <div className={Styles.formNodeWrap}>{formNode}</div>
                    </div>
                </ModalOrBottomSheetWrapper>
            ) : null}

            {/* Cancel bid confirmation modal */}
            {isConfirmCancelModalVisible ? (
                <CancelBidConfirmation
                    onClickSubmitBid={function () {
                        setIsConfirmCancelModalVisible(false);
                        trackEventWithName(GA4EventNames.MARKET_PLACE["continue_bidding"]);
                    }}
                    isVisible={isConfirmCancelModalVisible}
                    onClickConfirmCancel={onClickConfirmCancel}
                    handleCancelBidToggle={handleCancelBidToggle}
                />
            ) : null}
        </>
    );
};

export default FormContainer;
