/**
 * Once all the api is integrated, this hook should be moved to core
 * few api's will be used in admin also
 */
import API from "@vahak/core/dist/_services/const";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import {
    ClientHookInfiniteQueryOptions,
    useVahakInfiniteQuery,
    useVahakMutationQuery
} from "@vahak/core/dist/hooks/useVahakQueryClient";
import { QueryNames } from "../../core/dist/_services";
import { TripStatus } from "../components/premium/common/constants";
import { BULK_LOAD_CARD_STATUS_TEXT } from "@vahak/core/dist/constants/bulkLoadCardStatus";
import { API_REQUEST_TYPE } from "@vahak/core/dist/constants/api";
import { BID_STATUS } from "@vahak/core/dist/constants/bidStatus";
import { LORRY_BODY_TYPE, LORRY_CATEGORIES_ENUM } from "@vahak/core/dist/constants/lorryTypes";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import { getArrayWithType } from "@vahak/core/dist/methods/getArrayWithType";
import { LOAD_STATUS } from "@vahak/core/dist/constants/loadStatus";

export interface PremiumLoadListResponse {
    data: {
        total_pages: number;
        total_count: number;
        page_number: number;
        all: AllPremiumLoads[];
    };
}

export interface BidInfoForGroup {
    BidId: number;
    lorryId: number;
    tripStatus: TripStatus;
    lorryNumber: string;
}

export interface PremiumLoadBidsInfo {
    id?: number;
    load_id: number;
    lorry_id?: number;
    status: number;
    amount: number;
    type: number;
    cross_bid: number;
    bidder_id: number;
    tenderer_id: number;
    bidder_info: {
        id: number;
        name: string;
        logo: string;
        is_gst_verified: boolean;
        is_aadhaar_verified: boolean;
        is_pan_verified: boolean;
        is_payment_linked: boolean;
        phone_number: string;
    };
    tenderer_info: {
        id: number;
        name: string;
        logo: string;
        is_gst_verified: boolean;
        is_aadhaar_verified: boolean;
        is_pan_verified: boolean;
        is_payment_linked: boolean;
    };
    updated_by: number;
    updated_at: string;
    lorry_details: {
        source_info: {
            city_id: number;
            state_id: number;
            city_name: string;
            state_name: string;
        };
        source_text: string;
        size: number;
        size_text: string;
        capacity: number;
        total_tyres: number;
        routes: [
            {
                id: number;
                name: string;
            }
        ];
        trip_status?: number;
        number?: string;
        driver_name?: string;
        driver_phone_number?: string;
        expires_at: string;
        remarks: string; //not coming from api - remarks added by the time of bidding
    };
    quantity: number;

    //Custom data
    groupBidInfo: {
        BidId: number;
        driverName: string;
        driverNumber: string;
        lorryId: number;
        lorryNumber: string;
        tripStatus: TripStatus;
    }[];
    vehicleCount: number;
    availableLorryDetailsCount: number;
    //need to get from the api:
    tenderer_comment: string;
    lorryTypeId: number;
}

export interface AllPremiumLoads {
    /**
     * Only required type for premium load list card from api response will be added here
     */
    id: number;
    posted_time: string;
    loading_timestamp: number;
    report_time: string;
    material_type: string;
    expected_amount: number;
    accepted_price: number; //not coming from api
    number_of_vehicles: number;
    lorry_type: string;
    lorry_type_id: LORRY_CATEGORIES_ENUM;
    remarks: string;
    source: string;
    source_id: number;
    destination: string;
    destination_id: number;
    status: LOAD_STATUS;
    bulk_id?: number;
    bids_info: PremiumLoadBidsInfo[];
    payment_type: number;
    groupedByBidderId: PremiumLoadBidsInfo[];
    company_details: { company_id: number };
    quantity: number;
    advance_payment_percentage: number;
    status_text: string;
    length: number;
    breadth: number;
    height: number;
    is_odc: number;
    lorry_id: number;
    expected_type: number;
    numberOfBidAccepted: number;
    bidsCount: {
        [key in BID_STATUS]: number;
    };
    bulkLoadCardStatus: BULK_LOAD_CARD_STATUS_TEXT;
}

export interface PremiumLoadListParams {
    company_id: number;
    type?: string[];
    status?: string[] | string | undefined;
    loading_timestamp_string?: string;
    created_at_string?: string;
    page_number?: number;
    request_type?: number;
    id?: number[] | number; //Load id
    bulk_id?: number[] | number;
    lorry_history_fields?: string[];
    bid_status?: string[];
}

const getLoadCardStatus = (load: AllPremiumLoads): BULK_LOAD_CARD_STATUS_TEXT => {
    const { loading_timestamp, bidsCount, status } = load;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // loading_timestamp * 1000 converted to ms
    const isLoadingDatePassed = loading_timestamp * 1000 < currentDate.getTime();
    const noOfCompletedBid = bidsCount?.[BID_STATUS.COMPLETED] ?? 0;
    const noOfInTransitBid = bidsCount?.[BID_STATUS.IN_TRANSIT] ?? 0;
    const isLoadPartiallyFulfilled = isLoadingDatePassed && noOfCompletedBid && !noOfInTransitBid;
    switch (status) {
        case LOAD_STATUS.COMPLETED:
            return BULK_LOAD_CARD_STATUS_TEXT.FULFILLED;

        case LOAD_STATUS.UNDER_REVIEW:
            return BULK_LOAD_CARD_STATUS_TEXT.UNDER_REVIEW;

        case LOAD_STATUS.ACTIVE:
        case LOAD_STATUS.IN_TRANSIT:
            return isLoadPartiallyFulfilled
                ? BULK_LOAD_CARD_STATUS_TEXT.PARTIAL_FULFILLED
                : BULK_LOAD_CARD_STATUS_TEXT.PUBLISHED;

        case LOAD_STATUS.EXPIRED:
        default:
            return BULK_LOAD_CARD_STATUS_TEXT.EXPIRED;
    }
};

const getLoadListPayload = (data: PremiumLoadListParams) => {
    const {
        company_id,
        page_number = 1,
        type,
        status,
        id,
        loading_timestamp_string,
        created_at_string,
        bulk_id
    } = data;
    return {
        request_type: API_REQUEST_TYPE.ELASTIC_SEARCH,
        company_id,
        page_number: page_number,
        type: type?.[0].length === 0 ? undefined : type,
        loading_timestamp_string,
        created_at_string,
        status: getArrayWithType(status, String) as string[],
        id: getArrayWithType(id, Number) as number[],
        bulk_id: getArrayWithType(bulk_id, Number) as number[],
        lorry_history_fields: ["trip_status", "driver_name", "phone_number"],
        bid_status: getArrayWithType(
            [
                BID_STATUS.WAITING,
                BID_STATUS.IN_TRANSIT,
                BID_STATUS.EXPIRED,
                BID_STATUS.UNVERIFIED,
                BID_STATUS.COMPLETED
            ],
            String
        ) as string[]
    };
};

export function useGetFilteredLoadList() {
    const { POST } = useVahakHttpClient();

    const getLoads = async (params: PremiumLoadListParams) => {
        const response = await POST<PremiumLoadListParams, PremiumLoadListResponse>(
            API.ALL_LOADS,
            getLoadListPayload(params)
        );
        return renameLoadListResponseData(response);
    };

    return useVahakMutationQuery<PremiumLoadListResponse, ApiErrorResponseType, PremiumLoadListParams>(
        QueryNames.userLoads.adminVerifyLoad,
        getLoads
    );
}

export const usePremiumLoadList = (
    {
        company_id,
        page_number,
        type,
        status,
        id,
        loading_timestamp_string,
        created_at_string,
        bulk_id
    }: PremiumLoadListParams,
    options: ClientHookInfiniteQueryOptions<PremiumLoadListResponse, unknown, AllPremiumLoads>
) => {
    const { POST } = useVahakHttpClient();
    function getLoadsList({ pageParam = 1 }) {
        return POST<PremiumLoadListParams, PremiumLoadListResponse>(
            API.ALL_LOADS,
            getLoadListPayload({
                company_id,
                type,
                status,
                id,
                bulk_id,
                loading_timestamp_string,
                created_at_string,
                page_number: pageParam
            })
        );
    }

    return useVahakInfiniteQuery<PremiumLoadListResponse, unknown>(
        [
            QueryNames.premium.premiumLoadList,
            status,
            company_id,
            id,
            bulk_id,
            type,
            loading_timestamp_string,
            created_at_string
        ],
        getLoadsList,
        {
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage.data.page_number < lastPage.data.total_pages) return lastPage.data.page_number + 1;
                return undefined;
            },
            ...options,
            onSuccess: (data) => {
                let aggregatedData: AllPremiumLoads[] = [];
                data.pages.forEach((data) => {
                    const _loadsAllData = renameLoadListResponseData(data);
                    aggregatedData = [...aggregatedData, ..._loadsAllData.data.all];
                });
                options.onSuccess(aggregatedData);
            }
        }
    );
};

export interface editBulkLoadData {
    loads: {
        id: number;
        status: number;
        source_id: number;
        destination_id: number;
        remarks?: string;
        request_type: number;
        capacity?: number;
        total_tyres?: number;
        body_type?: LORRY_BODY_TYPE | null;
        size?: number;
        size_text?: string;
        lorry_type_id?: LORRY_CATEGORIES_ENUM;
        // add trailer body
        // add tanker body
    }[];
}

export interface editBulkLoadResponse {
    data: {
        results: [
            {
                id: number;
                status: number;
                message: string;
            }
        ];
    };
}

export const usePremiumBulkLoadEdit = () => {
    const { POST } = useVahakHttpClient();
    function editBulkLoad(data: editBulkLoadData) {
        return POST<editBulkLoadData, editBulkLoadResponse>(API.PREMIUM_BULK_LOAD_EDIT, { ...data });
    }
    return useVahakMutationQuery<editBulkLoadResponse, unknown, editBulkLoadData>(
        QueryNames.premium.editBulkLoad,
        editBulkLoad
    );
};

export interface deleteBulkLoadData {
    id: number[];
}

export interface deleteBulkLoadResponse {
    results: [
        {
            id: number;
            status: number;
        }
    ];
}

export const usePremiumBulkLoadDelete = () => {
    const { POST } = useVahakHttpClient();
    function deleteBulkLoad(data: deleteBulkLoadData) {
        return POST<deleteBulkLoadData, deleteBulkLoadResponse>(API.PREMIUM_BULK_LOAD_DELETE, { ...data });
    }
    return useVahakMutationQuery<deleteBulkLoadResponse, unknown, deleteBulkLoadData>(
        QueryNames.premium.deleteBulkLoad,
        deleteBulkLoad
    );
};

export interface PremiumLoadPublishData {
    quantity: number;
    length: number;
    breadth: number;
    height: number;
    expected_amount: number;
    material_type: string;
    payment_type: number;
    is_odc: number;
    remarks?: string;
    company_id: number;
    lorry_id: number;
    source_id: number;
    destination_id: number;
    is_reposted: boolean;
    expected_type: number;
    status: number;
    status_text: string;
    loading_timestamp: number;
    number_of_vehicles: number;
}

export interface publishBulkLoadData {
    loads: PremiumLoadPublishData[];
}

export interface publishBulkLoadResponse {
    data: {
        results: [
            {
                status: number;
            }
        ];
    };
    debug_info: {
        "correlation-id": string;
    };
}

export const usePremiumBulkLoadPublish = () => {
    const { POST } = useVahakHttpClient();
    function publishBulkLoad(data: publishBulkLoadData) {
        return POST<publishBulkLoadData, publishBulkLoadResponse>(API.PREMIUM_BULK_LOAD_PUBLISH, data);
    }
    return useVahakMutationQuery<publishBulkLoadResponse, unknown, publishBulkLoadData>(
        QueryNames.premium.publishBulkLoad,
        publishBulkLoad
    );
};

const renameLoadListResponseData = (data: PremiumLoadListResponse) => {
    data.data.all.map((allLoads) => {
        allLoads.bidsCount = {} as AllPremiumLoads["bidsCount"];
        const { bidsCount } = allLoads;
        let groupBidByBidderId: PremiumLoadBidsInfo[] = [];
        allLoads?.bids_info?.map((data) => {
            const index = groupBidByBidderId.findIndex((e) => e.bidder_id === data.bidder_id);
            //To delete grouped data from object without deleting bid_info object value, this copy obj is used
            let copyObj = JSON.parse(JSON.stringify(data));
            copyObj.quantity = allLoads.quantity;
            let lorryDetails = copyObj.lorry_details;
            let driverPhoneNum = lorryDetails.driver_phone_number;
            if (copyObj.lorry_details.history) {
                lorryDetails = copyObj.lorry_details.history[copyObj.lorry_details.history.length - 1].HistoryRecord;
                driverPhoneNum = lorryDetails.phone_number;
            }

            let noOfSameStatusBid = bidsCount?.[copyObj.status as BID_STATUS] ?? 0;
            allLoads.bidsCount[copyObj.status as BID_STATUS] = noOfSameStatusBid + 1;

            if (![BID_STATUS.REJECTED, BID_STATUS.NEGOTIATED].includes(copyObj.status)) {
                if (index === -1) {
                    const obj = {
                        groupBidInfo: [
                            {
                                BidId: copyObj.id,
                                lorryId: copyObj.lorry_id,
                                tripStatus: lorryDetails.trip_status,
                                lorryNumber: copyObj.lorry_details.number,
                                driverName: lorryDetails.driver_name,
                                driverNumber: driverPhoneNum
                            }
                        ]
                    };
                    delete copyObj.id;
                    delete copyObj.lorry_id;
                    delete copyObj.lorry_details.number;
                    groupBidByBidderId.push({
                        ...copyObj,
                        ...obj,
                        vehicleCount: Math.min(obj.groupBidInfo.length, allLoads.number_of_vehicles),
                        availableLorryDetailsCount: obj.groupBidInfo.filter((e) => e.tripStatus !== 0).length,
                        lorryTypeId: allLoads.lorry_type_id
                    });
                    delete copyObj.lorry_details.trip_status;
                } else {
                    const obj = {
                        groupBidInfo: [
                            ...groupBidByBidderId[index].groupBidInfo,
                            {
                                BidId: copyObj.id,
                                lorryId: copyObj.lorry_id,
                                tripStatus: lorryDetails.trip_status,
                                lorryNumber: copyObj.lorry_details.number,
                                driverName: lorryDetails.driver_name,
                                driverNumber: driverPhoneNum
                            }
                        ]
                    };
                    delete copyObj.id;
                    delete copyObj.lorry_id;
                    delete copyObj.lorry_details.number;
                    groupBidByBidderId[index] = {
                        ...copyObj,
                        ...obj,
                        vehicleCount: Math.min(obj.groupBidInfo.length, allLoads.number_of_vehicles),
                        availableLorryDetailsCount: obj.groupBidInfo.filter((e) => e.tripStatus !== 0).length,
                        lorryTypeId: allLoads.lorry_type_id
                    };
                    delete copyObj.lorry_details.trip_status;
                }
            }
        });
        allLoads.groupedByBidderId = groupBidByBidderId;

        allLoads.numberOfBidAccepted =
            (bidsCount?.[BID_STATUS.COMPLETED] ?? 0) + (bidsCount?.[BID_STATUS.IN_TRANSIT] ?? 0);
        allLoads.bulkLoadCardStatus = getLoadCardStatus(allLoads);
        return data;
    });
    return data;
};
