import { useQueryClient } from "react-query";
import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import { TicketInformationParamType } from "../../components/admin/ticket-system-helper/types";
import { TicketStatusMap } from "../../components/admin/ticket-system-helper/constants";

interface UpdateTicketStatusParams {
    ticket_id: number;
    status: TicketStatusMap;
    reason?: string;
    ticket_information?: TicketInformationParamType;
    gst_images?: {
        gst_image_url: string;
        shop_image: string;
    };
    remarks?: string;
}

interface UpdateTicketStatusResponse {
    data: { message: string };
}

export const useUpdateTicketStatusMutation = () => {
    const { POST } = useVahakHttpClient();
    const queryClient = useQueryClient();

    const updateTicketStatus = (data: UpdateTicketStatusParams) => {
        return POST<UpdateTicketStatusParams, UpdateTicketStatusResponse>(API.UPDATE_TICKET_STATUS, {
            ...data
        });
    };

    return useVahakMutationQuery<UpdateTicketStatusResponse, ApiErrorResponseType, UpdateTicketStatusParams>(
        QueryNames.ticketSystem.updateTicketStatus,
        updateTicketStatus,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([QueryNames.ticketSystem.getTicketById]);
            }
        }
    );
};
