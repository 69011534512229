import { companyNameModel } from "@vahak/core/dist/components/forms/company-name-form/companyNameModel";
import { otpModel } from "@vahak/core/dist/components/forms/otp-form/otpModel";
import { phoneNumModel } from "@vahak/core/dist/components/forms/phone-num-form/phoneNumModel";
import { serviceTypeModel } from "@vahak/core/dist/components/forms/service-type-form/serviceTypeModel";

export const commonBidModel = {
    price: {
        id: "price",
        fields: {
            amount: "amount",
            isFixedPrice: "isFixedPrice",
            isNegotiable: "isNegotiable",
            paymentType: "paymentType",
            remarks: "remarks"
        }
    },
    phoneNum: phoneNumModel,
    otp: otpModel,
    companyName: companyNameModel,
    serviceType: serviceTypeModel
};

export const loadBidModel = {
    ...commonBidModel,
    fleet: {
        id: "fleet",
        fields: {
            lorrySelection: "lorrySelection",
            lorryNum: "lorryNum",
            lorryType: "lorryType",
            lorryCapacity: "lorryCapacity",
            lorryId: "lorryId",
            routeIds: "routeIds",
            currentPlaceID: "currentPlaceID",
            currentCityId: "currentCityId",
            isLorryActive: "isLorryActive"
        }
    },
    currentLocation: {
        id: "currentLocation",
        fields: {
            currentPlaceID: "currentPlaceID",
            currentCityId: "currentCityId"
        }
    }
};

export const lorryBidModel = {
    ...commonBidModel,
    load: {
        id: "load",
        fields: {
            material: "material",
            qty: "qty",
            src: "src",
            dest: "dest",
            srcPlaceId: "srcPlaceId",
            destPlaceId: "destPlaceId",
            isLoadAvailable: "isLoadAvailable",
            loadId: "loadId"
        }
    }
};
