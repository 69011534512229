import API from "@vahak/core/dist/_services/const";
import { useVahakMutationQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";
import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { ApiErrorResponseType } from "@vahak/core/dist/custom-types/api-error-response-type";
import { ExpectedAmountType } from "@vahak/core/dist/constants";

interface UpdateExpectedAmountParams {
    load_id: number;
    expected_amount: number;
    expected_type: ExpectedAmountType;
}

interface UpdateExpectedAmountResponse {
    data: { message: string };
}

export const useUpdateExpectedAmount = () => {
    const { POST } = useVahakHttpClient();

    const method = (data: UpdateExpectedAmountParams) => {
        return POST<UpdateExpectedAmountParams, UpdateExpectedAmountResponse>(API.UPDATE_EXPECTED_AMOUNT, {
            ...data
        });
    };

    return useVahakMutationQuery<UpdateExpectedAmountResponse, ApiErrorResponseType, UpdateExpectedAmountParams>(
        QueryNames.load.updateExpectedAmount,
        method
    );
};
