import React, { ReactNode } from "react";
import Styles from "./QuickActionPopup.module.scss";
// Components
import Button, { ButtonProps } from "@vahak/core-ui/dist/components/Button";
import ModalOrBottomSheetWrapper, {
    ModalOrBottomSheetWrapperProps
} from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Flex from "@vahak/core-ui/dist/layout/Flex";

export interface QuickActionPopupProps {
    id?: string;
    ctaText?: ReactNode;
    ctaAction?: () => void;
    ctaProps?: Partial<ButtonProps>;
    secondaryCtaText?: ReactNode;
    secondaryCtaAction?: () => void;
    secondaryCtaProps?: Partial<ButtonProps>;
    header?: ReactNode;
    headerProps?: Partial<React.HTMLAttributes<HTMLDivElement>>;
    description?: ReactNode;
    isOpen?: boolean;
    onToggle?: () => void;
    showCloseIcon?: boolean;
    modalSize?: ModalOrBottomSheetWrapperProps["modalSize"];
    spacing?: number;
}
const QuickActionPopup = ({
    id = "quick-pop",
    description,
    isOpen,
    onToggle,
    header,
    ctaText,
    ctaAction,
    ctaProps,
    secondaryCtaProps,
    secondaryCtaAction,
    secondaryCtaText,
    showCloseIcon = true,
    headerProps,
    modalSize = "wide",
    spacing
}: QuickActionPopupProps) => {
    return (
        <>
            {isOpen ? (
                <ModalOrBottomSheetWrapper
                    isOpen={isOpen}
                    showCloseIcon={showCloseIcon}
                    onToggle={onToggle}
                    modalSize={modalSize}
                    sheetPaddingBottom={20}
                    modalSeparationGap={0}
                    id={id}
                    borderRadius={8}
                >
                    <Flex className={Styles.main} flexDirection="column" gap={spacing ?? 20}>
                        <div className={Styles.header} {...headerProps}>
                            {header}
                        </div>
                        <div className={Styles.description}>{description}</div>
                        <div className={Styles.ctaContainer}>
                            {secondaryCtaText ? (
                                <Button
                                    onClick={secondaryCtaAction}
                                    id={id + "-secondary"}
                                    fillType="outlined"
                                    {...secondaryCtaProps}
                                >
                                    {secondaryCtaText}
                                </Button>
                            ) : null}

                            {ctaText ? (
                                <Button onClick={ctaAction} id={id + "-primary"} {...ctaProps}>
                                    {ctaText}
                                </Button>
                            ) : null}
                        </div>
                    </Flex>
                </ModalOrBottomSheetWrapper>
            ) : null}
        </>
    );
};

export default QuickActionPopup;
