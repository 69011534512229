import React, { useState } from "react";
// Components
import TextArea, { onChangeTextAreaType } from "@vahak/core-ui/dist/components/TextArea";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import Select, {
    OnChangeSelectHandlerType,
    SelectOnChangeType,
    SelectOption
} from "@vahak/core-ui/dist/components/Select";
import Typography from "@vahak/core-ui/dist/components/Typography";
// Constants
import { TicketSysIDs } from "../ticket-system-helper/TicketSysIDs";
import { LoadTicketType } from "../ticket-system-helper/constants";
// Methods
import { generateFormFieldNames } from "../../../utils";
import { LOAD_DISPOSITION } from "../ticket-system-helper/loadDispositions";
import { DISPOSITION_PRICE_MISMATCH } from "../ticket-system-helper/disposition";
import Input, { InputChangeHandler } from "@vahak/core-ui/dist/components/Input";
import { Rupee } from "@vahak/core-ui/dist/constants/symbol";
import { stringOperations } from "@vahak/core-ui/dist/methods/string";

export interface LoadRemarksSubmitParams {
    comment: string;
    disposition: string;
    subDisposition: string;
}

interface UpdateLoadRemarksPropsType {
    onSubmit: (params: LoadRemarksSubmitParams) => void;
    ticketType: LoadTicketType;
    comment?: string;
}

const UpdateLoadRemarks = ({ onSubmit, ticketType, comment }: UpdateLoadRemarksPropsType) => {
    type DispositionType = typeof LOAD_DISPOSITION[keyof typeof LOAD_DISPOSITION][number];

    const formFieldNames = generateFormFieldNames({
        comment: "",
        disposition: "",
        subDisposition: ""
    });

    const [formData, setFormData] = useState<{
        [formFieldNames.comment]?: string;
        [formFieldNames.disposition]?: SelectOption<DispositionType>;
        [formFieldNames.subDisposition]?: SelectOption<string>;
    }>({
        comment: comment ?? ""
    });

    const inputType = "text";

    const trimmedCommentText =
        inputType === "text" ? formData?.comment?.trim() : Number(formData?.[formFieldNames.comment]);

    const dispositionList: SelectOption<DispositionType>[] = LOAD_DISPOSITION[ticketType].map((disposition) => ({
        label: disposition.dispositionName,
        value: disposition
    }));

    const subDispositionList = formData?.disposition?.value?.subDispositions?.map((str) => ({
        label: str,
        value: str
    }));

    const subDispositionRequired = !!subDispositionList?.length;

    const handleSubmit = () => {
        onSubmit({
            comment: formData[formFieldNames.comment] ?? "-",
            disposition: formData[formFieldNames.disposition]?.label ?? "-",
            subDisposition: formData[formFieldNames.subDisposition]?.label ?? "-"
        });
    };

    const handleSelectChange: OnChangeSelectHandlerType<false> = (option, actionMeta) => {
        setFormData((data) => ({
            ...data,
            [formFieldNames.subDisposition]: undefined,
            [actionMeta?.name ?? ""]: option
        }));
    };

    const handleOnChangeTextArea: onChangeTextAreaType = (e) => {
        setFormData((d) => ({ ...d, [e.target.name]: e.target.value }));
    };

    const handleOnChangeInput: InputChangeHandler = (e) => {
        if (e.target.name === formFieldNames.comment) {
            setFormData((d) => ({
                ...d,
                [e.target.name]: Number(stringOperations(e.target.value, ["removeNonNumeric"])).toString()
            }));
        }
    };

    const canContinue =
        trimmedCommentText &&
        !!formData[formFieldNames.disposition] &&
        (subDispositionRequired ? !!formData[formFieldNames.subDisposition] : true);

    return (
        <Flex flexDirection="column" gap={50} justifyContent="flex-end" padding="25px 0">
            <Flex flexDirection="column" gap={10}>
                <Typography required size="m">
                    Disposition :
                </Typography>

                <Select
                    id={TicketSysIDs.loadTicket.disposition}
                    name={formFieldNames.disposition}
                    option={dispositionList}
                    value={formData.disposition}
                    placeholder={"Disposition"}
                    onChange={handleSelectChange as SelectOnChangeType}
                    isSearchable
                />
            </Flex>

            {subDispositionRequired ? (
                <Flex flexDirection="column" gap={10}>
                    <Typography required size="m">
                        Sub-Disposition :
                    </Typography>
                    <Select
                        id={TicketSysIDs.loadTicket.subDisposition}
                        name={formFieldNames.subDisposition}
                        option={subDispositionList}
                        value={formData.subDisposition}
                        onChange={handleSelectChange as SelectOnChangeType}
                        placeholder={"Sub-Disposition"}
                        isSearchable
                    />
                </Flex>
            ) : null}

            {inputType === "text" ? (
                <TextArea
                    value={formData.comment}
                    name={formFieldNames.comment}
                    onChange={handleOnChangeTextArea}
                    placeholder="Remarks here..."
                    label={"CS Remarks"}
                    rows={3}
                    id={TicketSysIDs.loadTicket.commentBox}
                    required
                />
            ) : (
                <Input
                    value={formData.comment || ""}
                    name={formFieldNames.comment}
                    onChange={handleOnChangeInput}
                    id={TicketSysIDs.loadTicket.commentBox}
                    type="text"
                    placeholder="Enter suggested price"
                    prefixNode={Rupee}
                />
            )}
            <Flex justifyContent="flex-end" gap={20}>
                <Button
                    disabled={!canContinue}
                    onClick={handleSubmit}
                    width={150}
                    id={TicketSysIDs.loadTicket.primaryBtn}
                >
                    Update
                </Button>
            </Flex>
        </Flex>
    );
};

export default UpdateLoadRemarks;
