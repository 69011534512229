import * as Yup from "yup";
import { phoneRegExp } from "@vahak/core/dist/constants/regex";
import { commonBidModel } from "./bidFormModels";
import { companyNameRegExpMsg } from "@vahak/core/dist/constants/regex";
import { isValidCompanyName } from "@vahak/core/dist/methods/isValidCompanyName";

const { price, phoneNum, otp, companyName, serviceType } = commonBidModel;

export const getCommonValidationSchema = () => ({
    [price.id]: Yup.object().shape({
        [price.fields.amount]: Yup.string()
            .required("Required!")
            .test("min-values-check", "The bid amount is too low.", (value, context: any) => {
                let quantity = context.parent.quantity || context.parent.loadQuantity || context.parent.lorryCapacity;

                if (context.parent.isFixedPrice) {
                    return Number(value) >= 1000;
                } else if (context.parent.lorryCapacity && !context.parent.loadQuantity) {
                    return Math.ceil(context.parent.lorryCapacity) * Number(value) >= 1000;
                } else {
                    return quantity * Number(value) >= 1000;
                }
            })
        // .max(7, "Please enter valid amount.")
        // .test("no-leading-zero", "Leading zero is not allowed", (value, context: any) => {
        //     return context.originalValue && !context.originalValue.toString().startsWith("0");
        // })
        // .test("min-length", "Please enter valid amount.", (value: any, context: any) => {
        //     return context.parent.isFixedPrice ? value?.length >= 3 : value?.length >= 2;
        // })
    }),
    [phoneNum.id]: Yup.object().shape({
        [phoneNum.fields.phoneNum]: Yup.string()
            .matches(phoneRegExp, "Invalid number")
            .min(10, "Invalid number")
            .max(10, "Invalid number")
            .required("Required!")
    }),
    [otp.id]: Yup.object().shape({
        [otp.fields.otp]: Yup.number()
            .required("Required!")
            .integer()
            .positive()
            .test("len", (val) => val?.toString().length === 4)
    }),
    [companyName.id]: Yup.object().shape({
        [companyName.fields.companyName]: Yup.string().test("", companyNameRegExpMsg, isValidCompanyName),
        [companyName.fields.currentPlaceID]: Yup.string().required("Required!")
    }),
    [serviceType.id]: Yup.object().shape({
        [serviceType.fields.serviceType]: Yup.string().required("Required!")
    })
});
